import React from "react";
import look_up_table from '../images/perceptually_optimizing_color_lookup_tables.png';
import multiPrinter_learning_framework from'../images/multiPrinter_learning_framework.png';
import logo_white from '../images/logo_white.png';
import Goniochromatic_3D_Printing from '../images/Goniochromatic_3D_Printing.jpg';
import printing from '../images/3D_Printing.PNG';
import eyes from '../images/artificial_eyes1.JPG';
import "./HomeCarousel.css"; 
import { Link } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';

function HomeCarousel(){
    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.isMobile);
    
    function handleOnclick(){
         dispatch({ type: "SETTER", data: 3});
    }

    return(
            <div id="carouselExampleIndicators" class="carousel slide d-flex justify-content-center" data-bs-ride="carousel">
                <div class={isMobile? "d-none":"carousel-indicators m-5"} id="indicator">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"   class="active" aria-current="true"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                </div>

                <div class="carousel-inner" id="slides">
                    <div class="carousel-item" xdata-bs-interval="1000">
                        <div className="w-100 h-100 d-flex justify-content-center"  id="slide1">
                            <img src={Goniochromatic_3D_Printing} class="d-block w-100" alt="Goniochromatic_3D_Printing"/>
                        </div>
                        <div className="pdiv d-flex bg-light border justify-content-center">
                            <h2 className="text-black">
                            Our paper on goniochromatic 3D printing was accepted to SIGGRAPH 2023
                            </h2>
                        </div>
                        <Link  onClick={handleOnclick} className={isMobile? "moreInfo_smallScreen":"moreInfo" } to="/news/OurPaperOnGoniochromatic3DPrintingWasAcceptedToSIGGRAPH2023">
                            <h5 className="m-0"  id="text">More Info</h5>
                        </Link>
                    </div>


                    <div class="carousel-item active" xdata-bs-interval="1000">
                        <div className="w-100 h-100 d-flex justify-content-center "  id="slide2">
                            <img src={logo_white} class="d-block xw-100" alt="New release Cuttlefish"/>
                        </div>
                        <div className="pdiv d-flex bg-light border justify-content-center">
                            <h2 className="text-black">
                                New release Cuttlefish<sup>®</sup> 2024.R1
                            </h2>
                        </div>
                        <Link  onClick={handleOnclick} className={isMobile? "moreInfo_smallScreen":"moreInfo" } to="/news/Cuttlefish2024R1Released">
                            <h5 className="m-0"  id="text">More Info</h5>
                        </Link>
                    </div>
             					
					 <div class="carousel-item" xdata-bs-interval="1000">
						<div className="w-100 h-100 d-flex justify-content-center"  id="slide3">
							<img src={printing} class="d-block xw-100" alt="Our Paper on Cuttlefish: Pushing the Limits of Graphical 3-D Printing was accepted to IEEE"/>
						</div>
						<div className="pdiv d-flex bg-light border justify-content-center">
							<h3 className="text-black">
							    Our paper on Cuttlefish: Pushing the Limits of Graphical 3-D Printing was accepted to IEEE CG&A
							</h3>
						</div>
						<Link  onClick={handleOnclick} className={isMobile? "moreInfo_smallScreen":"moreInfo" } to="/news/OurPaperOnPushingTheLimitsOfGraphical3DPrintingWasAcceptedToIEEE">
							<h5 className="m-0"  id="text">More Info</h5>
						</Link>
					</div>	

                    <div class="carousel-item" xdata-bs-interval="1000">
						<div className="w-100 h-100 d-flex justify-content-center"  id="slide4">
							<img src={look_up_table} class="d-block xw-100" alt="Our paper on Optimizing Color Look-up-tables is published in IEEE TIP"/>
						</div>
						<div className="pdiv d-flex bg-light border justify-content-center">
							<h2 className="text-black">
							Our paper on Optimizing Color Look-up-tables is published in IEEE TIP
							</h2>
						</div>
						<Link  onClick={handleOnclick} className={isMobile? "moreInfo_smallScreen":"moreInfo" } to="/news/WeReleasedANewIEEEArticle">
							<h5 className="m-0"  id="text">More Info</h5>
						</Link>
					</div>	

                    <div class="carousel-item" xdata-bs-interval="1000">
						<div className="w-100 h-100 d-flex justify-content-center"  id="slide5">
							<img src={multiPrinter_learning_framework} class="d-block xw-100" alt="Our paper on Multi-Printer Deep Learning framework was published"/>
						</div>
						<div className="pdiv d-flex bg-light border justify-content-center">
							<h2 className="text-black">
							Our paper on Multi-Printer Deep Learning framework was published in Optics Express
							</h2>
						</div>
						<Link  onClick={handleOnclick} className={isMobile? "moreInfo_smallScreen":"moreInfo" } to="/news/OurPaperOnMultiPrinterDeepLearningFrameworkWasPublishedInOpticsExpress">
							<h5 className="m-0"  id="text">More Info</h5>
						</Link>
					</div>	

                    <div class="carousel-item" xdata-bs-interval="1000">
						<div className="w-100 h-100 d-flex justify-content-center"  id="slide6">
							<img src={eyes} class="d-block xw-100" alt="Our paper describing Cuttlefish::Eye and the 3D-printed ocular prostheses was published in Nature Communications"/>
						</div>
						<div className="pdiv d-flex bg-light border justify-content-center">
							<h4 className="text-black">
                            Our paper describing Cuttlefish::Eye and the 3D-printed ocular prostheses was published in Nature Communications
							</h4>
						</div>
						<Link  onClick={handleOnclick} className={isMobile? "moreInfo_smallScreen":"moreInfo" } to="/news/OurPaperDescribingCuttlefishEyeAndThe3DprintedOcularProsthesesWasPublishedInNatureCommunications">
							<h5 className="m-0"  id="text">More Info</h5>
						</Link>
					</div>
                    
				  </div>	

                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
	
            </div>
    );

}

export default HomeCarousel;