/* THIS IS A GENERATED FILE AND WILL BE OVERWRITEN. DO NOT COMMIT TO VERSION CONTROL */

import r0 from "./3D-Druck_Fraunhofer-begeistert-US-Kinomacher_LAIKA.jpg";
import p1 from "./3DPrintingFraunhoferThrillsUSMoviemakers.md";
import r2 from "./3d-druck_augenprothese.jpg";
import r3 from "./3d-druck_augenprothese_2.jpg";
import r4 from "./Anatomy_dark.jpg";
import p5 from "./CIC23InDarmstadt3DPrintingAndCuttlefishFeatureProminently.md";
import p6 from "./Color-calibratedReplicasUsing3DPrinting.md";
import p7 from "./Cuttlefish2022R1Released.md";
import p8 from "./Cuttlefish2022R2Released.md";
import p9 from "./Cuttlefish2023R1Released.md";
import p10 from "./Cuttlefish2023R2Released.md";
import p11 from "./Cuttlefish2024R1Released.md";
import p12 from "./CuttlefishAtFormNext2016.md";
import p13 from "./CuttlefishAtFormNext2018.md";
import p14 from "./CuttlefishAtFormNext2019.md";
import p15 from "./CuttlefishAtFormNext2021.md";
import p16 from "./CuttlefishAtFormNext2022.md";
import p17 from "./CuttlefishAtFormNext2023.md";
import p18 from "./CuttlefishAtFormnext2017.md";
import p19 from "./CuttlefishGoesMetal.md";
import p20 from "./CuttlefishMetalFirstPrints.md";
import p21 from "./CuttlefishNowAvailableToAllStratasysJ750Customers.md";
import p22 from "./CuttlefishPrintShownInBavarianMuseum.md";
import p23 from "./CuttlefishTechnologyHonoredByFraunhoferIGD&TUDarmstadt.md";
import p24 from "./DIGEDToUseCuttlefishFoIits3DPrintingService.md";
import p25 from "./DeutscheWelleBroadcastATVReportOnOurTechnology.md";
import p26 from "./DrivingTheMimaki3DUJ-553WithCuttlefish.md";
import p27 from "./FraunhoferIGDOptimizesCuttlefishForStratasysPolyJetApplications.md";
import p28 from "./FraunhoferTechnologyRevolutionises3DPrintingOfProstheticEyes.md";
import p29 from "./GCodeForFDM-Printers.md";
import r30 from "./Hand.jpg";
import r31 from "./IMG_0448.jpg";
import r32 from "./IMG_0734.jpg";
import r33 from "./IMG_3417.jpg";
import r34 from "./IMG_3418.jpg";
import r35 from "./IMG_3419.jpg";
import p36 from "./MediaReactionToOurTechnology.md";
import r37 from "./Mimaki_anatomy_small.png";
import p38 from "./MissingLink.md";
import p39 from "./NewReleaseCuttlefish2020.03.md";
import p40 from "./OurPaperDescribingCuttlefishEyeAndThe3DprintedOcularProsthesesWasPublishedInNatureCommunications.md";
import p41 from "./OurPaperOn3DPrintingColorAndTranslucencyAcceptedToSIGGRAPH.md";
import p42 from "./OurPaperOnDisplacedSignedDistanceFieldsWasAcceptedToSIGGRAPH2021.md";
import p43 from "./OurPaperOnGoniochromatic3DPrintingWasAcceptedToSIGGRAPH2023.md";
import p44 from "./OurPaperOnMultiPrinterDeepLearningFrameworkWasPublishedInOpticsExpress.md";
import p45 from "./OurPaperOnPushingTheLimitsOfGraphical3DPrintingWasAcceptedToIEEE.md";
import p46 from "./OurPaperOnShapeDitheringWasAcceptedToSIGGRAPH2022.md";
import p47 from "./OurPaperOnSurfaceHalftoningAcceptedToTOG.md";
import p48 from "./PresentationOfOurLatestPaperAtSIGGRAPHAsia.md";
import p49 from "./PresentingColorAndTranlucencyRepresentationAt3MFConsortiumMeeting.md";
import p50 from "./PushingTheLimitsOf3DColorPrinting.md";
import p51 from "./SlidesForOurSIGGRAPH2016TalkOnline.md";
import p52 from "./TheScienceBehindCuttlefishPresentedAtAPPAMATResearchGroupInaugurationDay.md";
import p53 from "./UpdatedDesignGuidelines.md";
import p54 from "./VisualComputingPodcast32016.md";
import p55 from "./VisualComputingReportVideoPodcast.md";
import p56 from "./WeReleasedANewIEEEArticle.md";
import p57 from "./WeReleasedANewOpticsExpressArticle.md";
import r58 from "./cuttlefish_slm.jpg";
import r59 from "./eye.jpg";
import r60 from "./image002.png";
import r61 from "./moorfields_patient.jpg";

export const posts = {
    "Cuttlefish2024R1Released": { 
        title: "Cuttlefish® 2024.R1 Released", description: null, 
        year: 2024, month: 5, day: 8,
        preview_image: null,
        source: p11, root: "." },
    "OurPaperDescribingCuttlefishEyeAndThe3DprintedOcularProsthesesWasPublishedInNatureCommunications": { 
        title: "Our paper describing Cuttlefish::Eye and the 3D-printed ocular prostheses was published in Nature Communications", description: null, 
        year: 2024, month: 2, day: 27,
        preview_image: null,
        source: p40, root: "." },
    "CuttlefishAtFormNext2023": { 
        title: "Join us at Formnext, Hall 11.0, Booth D31, to explore a diverse range of Cuttlefish® 3D prints", description: null, 
        year: 2023, month: 11, day: 7,
        preview_image: null,
        source: p17, root: "." },
    "Cuttlefish2023R2Released": { 
        title: "Cuttlefish® 2023.R2 Released", description: null, 
        year: 2023, month: 10, day: 31,
        preview_image: null,
        source: p10, root: "." },
    "OurPaperOnPushingTheLimitsOfGraphical3DPrintingWasAcceptedToIEEE": { 
        title: "Our paper on Cuttlefish: Pushing the Limits of Graphical 3-D Printing was accepted to IEEE Computer Graphics and Applications", description: null, 
        year: 2023, month: 9, day: 14,
        preview_image: null,
        source: p45, root: "." },
    "Cuttlefish2023R1Released": { 
        title: "Cuttlefish® 2023.R1 Released", description: null, 
        year: 2023, month: 5, day: 22,
        preview_image: null,
        source: p9, root: "." },
    "OurPaperOnGoniochromatic3DPrintingWasAcceptedToSIGGRAPH2023": { 
        title: "Our paper on goniochromatic 3D printing was accepted to SIGGRAPH 2023", description: null, 
        year: 2023, month: 5, day: 10,
        preview_image: null,
        source: p43, root: "." },
    "OurPaperOnMultiPrinterDeepLearningFrameworkWasPublishedInOpticsExpress": { 
        title: "Our paper on Multi-Printer Deep Learning framework was published in Optics Express", description: null, 
        year: 2023, month: 4, day: 7,
        preview_image: null,
        source: p44, root: "." },
    "WeReleasedANewIEEEArticle": { 
        title: "Our paper on Optimizing Color Look-up-tables is published in IEEE TIP", description: null, 
        year: 2022, month: 12, day: 15,
        preview_image: null,
        source: p56, root: "." },
    "CuttlefishAtFormNext2022": { 
        title: "Cuttlefish at formnext 2022 in Frankfurt am Main", description: null, 
        year: 2022, month: 11, day: 9,
        preview_image: null,
        source: p16, root: "." },
    "Cuttlefish2022R2Released": { 
        title: "Cuttlefish® 2022.R2 Released", description: null, 
        year: 2022, month: 11, day: 8,
        preview_image: null,
        source: p8, root: "." },
    "OurPaperOnShapeDitheringWasAcceptedToSIGGRAPH2022": { 
        title: "Our paper on Shape Dithering was accepted to SIGGRAPH 2022", description: null, 
        year: 2022, month: 7, day: 26,
        preview_image: null,
        source: p46, root: "." },
    "Cuttlefish2022R1Released": { 
        title: "Cuttlefish® 2022.R1 Released", description: null, 
        year: 2022, month: 5, day: 2,
        preview_image: null,
        source: p7, root: "." },
    "FraunhoferTechnologyRevolutionises3DPrintingOfProstheticEyes": { 
        title: "Fraunhofer technology revolutionises 3D printing of prosthetic eyes", description: null, 
        year: 2021, month: 12, day: 6,
        preview_image: null,
        source: p28, root: "." },
    "CuttlefishAtFormNext2021": { 
        title: "Cuttlefish at FormNext 2021 in Frankfurt am Main", description: null, 
        year: 2021, month: 11, day: 11,
        preview_image: null,
        source: p15, root: "." },
    "FraunhoferIGDOptimizesCuttlefishForStratasysPolyJetApplications": { 
        title: "Fraunhofer IGD optimizes Cuttlefish® 3D printing driver for Stratasys Polyjet applications", description: null, 
        year: 2021, month: 8, day: 3,
        preview_image: null,
        source: p27, root: "." },
    "OurPaperOnDisplacedSignedDistanceFieldsWasAcceptedToSIGGRAPH2021": { 
        title: "Our paper on displaced signed distance fields was accepted to SIGGRAPH 2021", description: null, 
        year: 2021, month: 4, day: 28,
        preview_image: null,
        source: p42, root: "." },
    "WeReleasedANewOpticsExpressArticle": { 
        title: "We released a new Optics Express article", description: null, 
        year: 2021, month: 3, day: 19,
        preview_image: null,
        source: p57, root: "." },
    "UpdatedDesignGuidelines": { 
        title: "Updated design guidelines", description: null, 
        year: 2020, month: 6, day: 9,
        preview_image: null,
        source: p53, root: "." },
    "NewReleaseCuttlefish2020.03": { 
        title: "New release Cuttlefish 2020.03", description: null, 
        year: 2020, month: 5, day: 4,
        preview_image: null,
        source: p39, root: "." },
    "DrivingTheMimaki3DUJ-553WithCuttlefish": { 
        title: "Driving the Mimaki 3DUJ-553 with Cuttlefish", description: null, 
        year: 2019, month: 11, day: 21,
        preview_image: null,
        source: p26, root: "." },
    "CuttlefishAtFormNext2019": { 
        title: "Cuttlefish at FormNext 2019 in Frankfurt am Main", description: null, 
        year: 2019, month: 11, day: 19,
        preview_image: null,
        source: p14, root: "." },
    "PresentationOfOurLatestPaperAtSIGGRAPHAsia": { 
        title: "Presentation of our latest paper at SIGGRAPH Asia", description: null, 
        year: 2019, month: 11, day: 19,
        preview_image: null,
        source: p48, root: "." },
    "TheScienceBehindCuttlefishPresentedAtAPPAMATResearchGroupInaugurationDay": { 
        title: "The science behind Cuttlefish presented at APPAMAT research group inauguration day", description: null, 
        year: 2019, month: 7, day: 12,
        preview_image: null,
        source: p52, root: "." },
    "PresentingColorAndTranlucencyRepresentationAt3MFConsortiumMeeting": { 
        title: "Presenting color and tranlucency representation at 3MF consortium meeting", description: null, 
        year: 2019, month: 7, day: 1,
        preview_image: null,
        source: p49, root: "." },
    "MissingLink": { 
        title: "Fraunhofer and Cuttlefish mentioned by Brian McLean in TechCrunch’s behind the scene video of LAIKA’s next feature film “Missing Link”.", description: null, 
        year: 2019, month: 4, day: 12,
        preview_image: null,
        source: p38, root: "." },
    "CuttlefishMetalFirstPrints": { 
        title: "Cuttlefish Metal - First prints!", description: null, 
        year: 2019, month: 3, day: 5,
        preview_image: null,
        source: p20, root: "." },
    "CuttlefishAtFormNext2018": { 
        title: "Cuttlefish at FormNext 2018 in Frankfurt am Main", description: null, 
        year: 2018, month: 11, day: 9,
        preview_image: null,
        source: p13, root: "." },
    "Color-calibratedReplicasUsing3DPrinting": { 
        title: "Color-calibrated replicas using 3D printing", description: null, 
        year: 2018, month: 8, day: 29,
        preview_image: null,
        source: p6, root: "." },
    "CuttlefishPrintShownInBavarianMuseum": { 
        title: "Cuttlefish Prints Shown in Bavarian Museum", description: null, 
        year: 2018, month: 7, day: 18,
        preview_image: null,
        source: p22, root: "." },
    "OurPaperOn3DPrintingColorAndTranslucencyAcceptedToSIGGRAPH": { 
        title: "Our paper on 3D printing color and translucency accepted to SIGGRAPH", description: null, 
        year: 2018, month: 5, day: 17,
        preview_image: null,
        source: p41, root: "." },
    "CuttlefishGoesMetal": { 
        title: "Cuttlefish goes metal", description: null, 
        year: 2017, month: 12, day: 1,
        preview_image: null,
        source: p19, root: "." },
    "CuttlefishNowAvailableToAllStratasysJ750Customers": { 
        title: "Cuttlefish now available to all Stratasys J750 customers", description: null, 
        year: 2017, month: 11, day: 23,
        preview_image: null,
        source: p21, root: "." },
    "CuttlefishAtFormnext2017": { 
        title: "Cuttlefish at Formnext 2017 in Frankfurt am Main", description: null, 
        year: 2017, month: 11, day: 15,
        preview_image: null,
        source: p18, root: "." },
    "DIGEDToUseCuttlefishFoIits3DPrintingService": { 
        title: "DIG:ED to use Cuttlefish for its 3D printing service", description: null, 
        year: 2017, month: 8, day: 30,
        preview_image: null,
        source: p24, root: "." },
    "CuttlefishTechnologyHonoredByFraunhoferIGD&TUDarmstadt": { 
        title: "Cuttlefish technology honored by Fraunhofer IGD & TU Darmstadt!", description: null, 
        year: 2016, month: 12, day: 5,
        preview_image: null,
        source: p23, root: "." },
    "CuttlefishAtFormNext2016": { 
        title: "Cuttlefish at Formnext 2016 in Frankfurt am Main", description: null, 
        year: 2016, month: 11, day: 10,
        preview_image: null,
        source: p12, root: "." },
    "VisualComputingPodcast32016": { 
        title: "Visual Computing Podcast 3/2016", description: null, 
        year: 2016, month: 11, day: 1,
        preview_image: null,
        source: p54, root: "." },
    "SlidesForOurSIGGRAPH2016TalkOnline": { 
        title: "Slides for our SIGGRAPH 2016 talk online", description: null, 
        year: 2016, month: 8, day: 29,
        preview_image: null,
        source: p51, root: "." },
    "3DPrintingFraunhoferThrillsUSMoviemakers": { 
        title: "3D Printing - Fraunhofer thrills US moviemakers", description: null, 
        year: 2016, month: 5, day: 31,
        preview_image: null,
        source: p1, root: "." },
    "GCodeForFDM-Printers": { 
        title: "GCode for FDM-Printers", description: null, 
        year: 2016, month: 3, day: 16,
        preview_image: null,
        source: p29, root: "." },
    "DeutscheWelleBroadcastATVReportOnOurTechnology": { 
        title: "Deutsche Welle broadcast a TV report on our technology", description: null, 
        year: 2015, month: 12, day: 8,
        preview_image: null,
        source: p25, root: "." },
    "CIC23InDarmstadt3DPrintingAndCuttlefishFeatureProminently": { 
        title: "CIC 23 in Darmstadt - 3D Printing and Cuttlefish Feature Prominently", description: null, 
        year: 2015, month: 10, day: 30,
        preview_image: null,
        source: p5, root: "." },
    "OurPaperOnSurfaceHalftoningAcceptedToTOG": { 
        title: "Our Paper on Surface Halftoning Accepted to TOG", description: null, 
        year: 2015, month: 10, day: 1,
        preview_image: null,
        source: p47, root: "." },
    "MediaReactionToOurTechnology": { 
        title: "Media reaction to our technology", description: null, 
        year: 2015, month: 6, day: 25,
        preview_image: null,
        source: p36, root: "." },
    "PushingTheLimitsOf3DColorPrinting": { 
        title: "Pushing the Limits of 3D Color Printing", description: null, 
        year: 2015, month: 6, day: 8,
        preview_image: null,
        source: p50, root: "." },
    "VisualComputingReportVideoPodcast": { 
        title: "Visual Computing Report - Video Podcast", description: null, 
        year: 2015, month: 5, day: 27,
        preview_image: null,
        source: p55, root: "." },
};
export const resources = {
   "3D-Druck_Fraunhofer-begeistert-US-Kinomacher_LAIKA.jpg": r0,
   "3d-druck_augenprothese.jpg": r2,
   "3d-druck_augenprothese_2.jpg": r3,
   "Anatomy_dark.jpg": r4,
   "Hand.jpg": r30,
   "IMG_0448.jpg": r31,
   "IMG_0734.jpg": r32,
   "IMG_3417.jpg": r33,
   "IMG_3418.jpg": r34,
   "IMG_3419.jpg": r35,
   "Mimaki_anatomy_small.png": r37,
   "cuttlefish_slm.jpg": r58,
   "eye.jpg": r59,
   "image002.png": r60,
   "moorfields_patient.jpg": r61,
};
